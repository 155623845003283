
import React from "react";
import DeleteButton from "./DeleteButton";
import SubscriberIndicator from "./SubscriberIndicatior";
import RequesterName from "./RequesterName";


const RequestedSongs = (props) => {
    const [, setIsLoading] = React.useState(true);
    const [songs, setSongs] = React.useState([]);
    // const [listChanged, setListChanged] = React.useState(false);
    const loadPlaylist = async () => {
        const response = await fetch ("https://svc.livejukebox.band/session/playlist");
        const responseJson = await response.json();
        setSongs(responseJson);
    }

    React.useEffect(() => {
        (async () => {
            try{
                loadPlaylist();
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        })();
      }, []);
      React.useEffect(() => {
        (async () => {
            try{
                loadPlaylist();
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false);
            }
        })();
      }, [props.refreshNeeded]);

    
      React.useEffect(() => {
        const interval = setInterval(() => {
          loadPlaylist();
        }, 10*1000);
        return () => clearInterval(interval);
      }, []);

  return (
    <div className="content-start" >
      <div className="divide-y">
        {songs.map((song, index) => (
          <div key={index} id={index} className="py-2 px-2 flex flex-row justify-between ">
                <div className="order-1 w-5/6">
                    <div className="text-xl font-bold">
                      <span className="text-rose-700">{song.Artist}</span>
                      <br />
                      <span className="text-teal-700">{song.Title}</span>
                      </div>
                    <div className="italic text-2xl text-slate-500">&quot;{song.Message}&quot;</div>
                </div>
                <div className="order-2 px-4 py-2"><RequesterName requesterName={song.RequesterName}/></div>
                <div className="order-3 px-4 py-2"><SubscriberIndicator subscriber={song.SubscriberRequest}/></div>
                <div className="order-12">
                    <DeleteButton song={song} reloadFunction={loadPlaylist}/>
                </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default RequestedSongs;