import './App.css';
import RequestedSongs from './RequestedSongs';
import React from 'react';
function App() {
  const [refreshNeeded, setRefreshNeeded] = React.useState(false);

  const resetSession = async () => {
    const response = await fetch("https://svc.livejukebox.band/session/QcUM39Wi0W876Hi/panicmode", {method: "POST"});
    const responseJson = await response.json();
    setRefreshNeeded(!refreshNeeded);
    console.log(responseJson);
    
  }

  return (
    <div className="text-center">
      <header className="bg-slate-800	text-white">
        <div className="py-2 px-2 flex flex-row place-items-center justify-between ">
          <div><h1>Requested Songs</h1></div>
          <div className="text-xl"><button onClick={resetSession}>♻️</button></div>
        </div>
      </header>
      <div className="text-left">
      <RequestedSongs refreshNeeded={refreshNeeded}/>
      </div>
    </div>
  );
}

export default App;
