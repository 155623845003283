
import React from "react";
const DeleteButton = (song) => {
    async function deleteSong(s, evt){
        evt.preventDefault();
        // var response;
        var tmpsong = {
            Artist: s.song.Artist,
            Title: s.song.Title,
            Message: s.song.Message,
        };
        console.log(tmpsong);

        const response = await fetch("https://svc.livejukebox.band/session/delete", {method: "DELETE", body: JSON.stringify(tmpsong)});
        const responseJson = await response.json();
        // .then(response => response.json());
        console.log(responseJson);
        s.reloadFunction();
        
    }
    // float: right;
    // background-color: #132261;
    // color: white;
    // padding: 10px;
    // border: none;
    // border-radius: 5px;
    // /* margin-left: 20px; */
    // font-size: 20px;
    // cursor: pointer;
    return (
        
        <button onClick={(e) => deleteSong(song, e)} className="bg-slate-800 float-right px-2 py-2 text-3xl rounded-lg">❌</button>
      );
}

export default DeleteButton;